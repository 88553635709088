import React from "react"
import StructurePageSoporteconAmor from "@components/pageSoporte/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>¿Cómo Beex ayuda a todos sus clientes? [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/soporte-con-amor/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo Beex ofrece un soporte de calidad y por qué han optado este tipo de estrategia. Además, entérate qué opinan sus clientes al respecto."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="¿Cómo Beex ayuda a todos sus clientes?"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/soporte-con-amor/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Descubre cómo Beex ofrece un soporte de calidad y por qué han optado este tipo de estrategia. Además, entérate qué opinan sus clientes al respecto."
      />
    </Helmet>
    <StructurePageSoporteconAmor location={location} />
  </div>
)

export default IndexPage
