import React from "react"
import chats from "@components/pageSoporte/components/start/image/chats.svg"
import audifonos from "@components/pageSoporte/components/start/image/audifonos.svg"
import FotoGrupo from "@components/pageSoporte/components/start/image/fotosgrupo.webp"
import { Link } from "gatsby"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"


const Start = () => {
    return (
        <section className="container-intro-page-soporte">
            <section className="section-suport-start">
                <p className="container-intro-page-soporte-title">
                    Te brindamos la <TitleUnderlineOne underline="atención que" /> <TitleUnderlineTwo underline="mereces" />   y que no siempre recibes
                </p>
                <p className="">
                    Beex se distingue no solo por la calidad técnica de nuestra plataforma, sino por un enfoque humano y cercano en el servicio al cliente. Mientras que otros proveedores priorizan escalar su negocio sin dedicar suficiente atención a la experiencia de soporte, nosotros ponemos a nuestros clientes en el centro de todo lo que hacemos.
                    <br /> <br />
                    Sea por correo, llamada o por chat, por el medio que prefieras, nuestro equipo estará presente para brindarte una atención de calidad. Estamos conectados contigo las 24 horas del día y los 365 días del año. ¡Nunca te dejaremos de lado!
                </p>
                <br /> <br />
                <Link className="container-intro-page-soporte-button" href={`/hablemos/`} target="_blank"
                    rel="noopener noreferrer">
                    Quiero hablar con un experto
                </Link>
            </section>
            <img
                loading="lazy"
                className="container-intro-page-soporte-image"
                src={FotoGrupo}
                alt="fotos"
            />
        </section>
    )
}

export default Start
